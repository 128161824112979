<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />

    <b-card v-else>
      <template v-slot:header>
        <h5>Petugas Pemeliharaan</h5>
      </template>
      <div class="search-wrapper d-flex align-items-center justify-content-between">
        <div class="d-flex search-input-wrapper">
          <b-form-group class="form-inline form-group-wrapper mb-3">
            <b-form-select size="md" class="ml-0 mr-2" v-model="filter.selectFilter" @change="onResetInputFilter"
              :plain="false">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>Pencarian berdasarkan</b-form-select-option>
              </template>

              <b-form-select-option v-for="option in optionsFindStatusBy" v-bind:key="option.value"
                v-bind:value="option.value">
                {{ option.label }}
              </b-form-select-option>
            </b-form-select>

            <!-- Find by name -->

            <b-form-input type="text" class="mr-2" v-model="filter.name" placeholder="Nama Petugas"
              v-if="filter.selectFilter == 1"></b-form-input>

            <!-- Find by no hp -->

            <b-form-input type="text" class="mr-2" v-model="filter.hp" placeholder="No. HP Petugas"
              v-if="filter.selectFilter == 2"></b-form-input>

            <!-- Find by status -->
            <b-form-select v-if="filter.selectFilter == 3" size="md" class="mr-2" v-model="filter.status" :plain="false">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>Pilih Status</b-form-select-option>
              </template>

              <b-form-select-option v-for="option in optionsFindStatus" v-bind:key="option.value"
                v-bind:value="option.value">
                {{ option.label }}
              </b-form-select-option>
            </b-form-select>

            <b-button class="mr-2" variant="success" @click="getMaintenance"><i class="fa fa-search"></i> Cari</b-button>

            <b-button variant="primary" @click="onResetFilter"><i class="fa fa-undo"></i> Atur Ulang Pencarian</b-button>
          </b-form-group>
        </div>

        <b-button class="mb-3 mr-2" variant="primary" @click="$bvModal.show('m-create-maintenance')"><i
            class="fa fa-plus fs-6"></i> Tambah Data
        </b-button>
      </div>

      <!-- Table -->
      <b-table bordered hover show-empty :busy="isBusy" :items="maintenance" :fields="fields" :perPage="perPage"
        responsive class="mb-3">
        <template #empty="scope"> Data tidak ditemukan </template>

        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner small class="align-middle" variant="secondary"></b-spinner>
            <span class="font-weight-bold text-secondary">
              Harap tunggu...</span>
          </div>
        </template>

        <template v-slot:head(status)="props">
          <span>Kategori Petugas </span><b-badge v-b-popover.hover.bottom="'Petugas Pemeliharaan Pusat, Cabang atau Pengemudi.'
            " title="Catatan" pill variant="info"><i class="fa fa-info"></i></b-badge>
        </template>

        <template v-slot:cell(status_aktif)="row">
          <template v-if="row.item.status_aktif == 1">
            <b-badge variant="success">Aktif</b-badge>
          </template>

          <template v-else-if="row.item.status_aktif == 0">
            <b-badge variant="danger">Tidak Aktif</b-badge>
          </template>
        </template>

        <template v-slot:cell(foto)="row">
          <template v-if="row.item.foto === null">
            <div class="img-table">
              <expandable-image :src="$defaultUser" />
            </div>
          </template>

          <template v-else>
            <div class="img-table">
              <expandable-image :src="row.item.foto" />
            </div>
          </template>
        </template>

        <template v-slot:cell(action)="row">
          <div class="no-wrap">
            <b-button class="mr-1 btn btn-sm btn-warning" v-b-tooltip.hover title="Ubah" @click="
              showModalEdit(
                `${row.item.id}`,
                `${row.item.name}`,
                `${row.item.username}`,
                `${row.item.telp}`,
                `${row.item.area}`,
                `${row.item.status}`,
                `${row.item.status_aktif}`,
                `${row.item.email}`,
                `${row.item.keterangan}`,
                `${row.item.foto}`,
                `${row.item.ktp}`
              )
              ">
              <i class="fa fa-pencil"></i>
            </b-button>

            <b-button size="sm" variant="pgiBtn" v-b-tooltip.hover title="Detail"
              @click="showModalDetail(`${row.item.id}`)">
              <i class="fa fa-info mr-1 ml-1"></i>
            </b-button>

            <b-button class="ml-1 btn btn-sm btn-danger" v-b-tooltip.hover title="Hapus" @click="
              showSweetAlertDelete(`${row.item.id}`, `${row.item.name}`)
              ">
              <i class="fa fa-trash"></i>
            </b-button>
          </div>
        </template>
      </b-table>

      <div class="pagination-wrapper d-flex align-items-center justify-content-between">
        <b-input-group prepend="Menampilkan" class="mt-3 font-weight-bold">
          <b-input-group-append>
            <b-input-group-text class="bg-white">
              <strong class="mr-1">{{ totalViewText }}</strong>
              <span class="mr-1">dari</span>
              <strong>{{ rows }}</strong>
            </b-input-group-text>
            <b-input-group-text>entri</b-input-group-text>
          </b-input-group-append>
        </b-input-group>
        <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table"
          @input="onPageChange"></b-pagination>
      </div>
    </b-card>

    <!-- Modal Create -->
    <b-modal id="m-create-maintenance" size="lg" title="Tambah Petugas Pemeliharaan" ref="my-modal" @hidden="hideModal"
      no-close-on-backdrop no-close-on-esc hide-footer>
      <b-form @submit.prevent="onConfirm">
        <b-row>
          <b-col cols="12" sm="12" md="7" lg="7" xl="7">
            <b-form-group label="Nama Petugas" label-for="input-name" class="required">
              <b-form-input id="input-name" type="text" v-model="form.nama" placeholder="Nama Petugas"></b-form-input>
              <small v-if="!$v.form.nama.required && $v.form.nama.$dirty" class="text-danger">Kolom tidak boleh
                kosong</small>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12" md="5" lg="5" xl="5">
            <b-form-group label="Kontak Petugas" label-for="input-phone" class="required">
              <b-form-input id="input-phone" type="text" v-model="form.no_telp" v-on:keypress="NumbersOnly($event)"
                placeholder="contoh: 0813XXXX"></b-form-input>
              <small v-if="!$v.form.no_telp.required && $v.form.no_telp.$dirty" class="text-danger">Kolom tidak boleh
                kosong</small>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Nama Pengguna" label-for="input-username" class="required">
              <b-form-input id="input-usernane" type="text" v-model="form.username" placeholder="Nama Pengguna"
                v-on:keyup="checkUsernameCreate"></b-form-input>

              <small v-if="!$v.form.username.required && $v.form.username.$dirty" class="text-danger">Kolom tidak boleh
                kosong</small>

              <small :class="'text-' + classMsgValidationUsername">{{
                msgValidationUsername
              }}</small>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Email" label-for="input-email">
              <b-form-input id="input-email" type="email" v-model="form.email" placeholder="Email Petugas"
                v-on:keyup="handleInputOnKeyupCreate"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Kata Sandi" label-for="input-password" class="required">
              <b-form-input id="input-password" type="text" v-model="form.password"
                placeholder="Kata Sandi"></b-form-input>
              <small v-if="!$v.form.password.required && $v.form.password.$dirty" class="text-danger">Kolom tidak boleh
                kosong</small>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Wilayah" label-for="input-area" class="required">
              <b-form-input id="input-area" type="text" v-model="form.area" placeholder="contoh: Jakarta"></b-form-input>
              <small v-if="!$v.form.area.required && $v.form.area.$dirty" class="text-danger">Kolom tidak boleh
                kosong</small>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Status" label-for="select-status" class="required">
              <b-form-select v-model="form.status" size="md" :plain="false">
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>Pilih Status</b-form-select-option>
                </template>
                <b-form-select-option v-for="option in optionStatusMaintenance" v-bind:key="option.value"
                  v-bind:value="option.value">
                  {{ option.label }}
                </b-form-select-option>
              </b-form-select>
              <small v-if="!$v.form.status.required && $v.form.status.$dirty" class="text-danger">Kolom tidak boleh
                kosong</small>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Foto Petugas (Format: JPG, JPEG, PNG)" label-for="input-foto" class="mb-0">
              <span class="upload-btn-wrapper">
                <button class="btn-upload btn-sm">
                  <i class="fa fa-paperclip"></i> Pilih File
                </button>
                <input ref="imageUploader" type="file" accept="image/jpg, image/jpeg, image/png" prepend-icon="mdi-camera"
                  @change="onFileChange" />
              </span>
            </b-form-group>

            <b-row class="mt-2">
              <b-col sm="6">
                <div class="files">
                  <template v-if="form.files">
                    <expandable-image :src="form.files" />
                  </template>
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Foto KTP Petugas (Format: JPG, JPEG, PNG)" label-for="input-foto-ktp" class="mb-0">
              <span class="upload-btn-wrapper">
                <button class="btn-upload btn-sm">
                  <i class="fa fa-paperclip"></i> Pilih File
                </button>
                <input ref="imageUploader" type="file" accept="image/jpg, image/jpeg, image/png" prepend-icon="mdi-camera"
                  @change="onFileChangeKTP" />
              </span>
            </b-form-group>

            <b-row class="mt-2">
              <b-col sm="12">
                <div class="ktp">
                  <template v-if="form.ktp">
                    <expandable-image :src="form.ktp" />
                  </template>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <div class="mt-4 text-right">
          <template v-if="this.$v.form.$invalid || isDisabled">
            <b-button variant="pgiBtn" disabled>
              <i class="fa fa-save"></i> Simpan
            </b-button>
          </template>

          <template v-else>
            <b-button variant="pgiBtn" type="submit">
              <i class="fa fa-save"></i> Simpan
            </b-button>
          </template>

          <b-button class="ml-2" variant="dark" @click="hideModal"><i class="fa fa-times"></i> Batal</b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- Modal Edit -->
    <b-modal id="m-edit-maintenance" size="lg" title="Ubah Data Petugas" ref="my-modal" @hidden="hideModal"
      no-close-on-backdrop no-close-on-esc hide-footer>
      <b-form @submit.prevent="onConfirmEdit">
        <b-row>
          <b-col cols="12" md="7">
            <b-form-group label="Nama Petugas" label-for="input-name" class="required">
              <b-form-input id="input-name" type="text" v-model="formEdit.nama" placeholder="Nama Petugas"></b-form-input>
              <small v-if="!$v.formEdit.nama.required && $v.formEdit.nama.$dirty" class="text-danger">Kolom tidak boleh
                kosong</small>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="5">
            <b-form-group label="Kontak Petugas" label-for="input-phone" class="required">
              <b-form-input id="input-phone" type="text" v-on:keypress="NumbersOnly($event)" v-model="formEdit.no_telp"
                placeholder="contoh: 0813XXXX"></b-form-input>
              <small v-if="!$v.formEdit.no_telp.required && $v.formEdit.no_telp.$dirty
                " class="text-danger">Kolom tidak boleh kosong</small>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="Nama Pengguna" label-for="input-username" class="required">
              <b-form-input id="input-username" type="text" v-model="formEdit.username" placeholder="Nama Pengguna"
                v-on:keyup="checkUsernameUpdate"></b-form-input>

              <small v-if="!$v.formEdit.username.required && $v.formEdit.username.$dirty
                " class="text-danger">Kolom tidak boleh kosong</small>

              <small :class="'text-' + classMsgValidationUsername">{{
                msgValidationUsername
              }}</small>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="Email" label-for="input-email">
              <b-form-input id="input-email" type="text" v-model="formEdit.email" placeholder="Email Petugas"
                v-on:keyup="handleInputOnKeyupUpdate"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="Kata Sandi" label-for="input-password">
              <b-form-input id="input-password" type="text" v-model="formEdit.password"
                placeholder="Kata Sandi"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="Wilayah" label-for="input-area" class="required">
              <b-form-input id="input-area" type="text" v-model="formEdit.area"
                placeholder="contoh: Jakarta"></b-form-input>
              <small v-if="!$v.formEdit.area.required && $v.formEdit.area.$dirty" class="text-danger">Kolom tidak boleh
                kosong</small>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="Status" label-for="select-status" class="required">
              <b-form-select v-model="formEdit.status" size="md" :plain="false">
                <template v-slot:first>
                  <b-form-select-option :value="null" disabled>Pilih Status</b-form-select-option>
                </template>
                <b-form-select-option v-for="option in optionStatusMaintenance" v-bind:key="option.value"
                  v-bind:value="option.value">
                  {{ option.label }}
                </b-form-select-option>
              </b-form-select>
              <small v-if="!$v.formEdit.status.required && $v.formEdit.status.$dirty" class="text-danger">Kolom tidak
                boleh kosong</small>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="my-switch">
            <b-form-checkbox v-model="formEdit.active" name="check-button" switch @input="checkActive">
              <b>{{ formEdit.active ? "Aktif" : "Tidak Aktif" }}</b>
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Foto Petugas (Format: JPG, JPEG, PNG)" label-for="input-foto" class="mb-0">
              <span class="upload-btn-wrapper">
                <button class="btn-upload btn-sm">
                  <i class="fa fa-paperclip"></i> Pilih File
                </button>
                <input ref="imageUploader" type="file" accept="image/jpg, image/jpeg, image/png" prepend-icon="mdi-camera"
                  @change="onFileChangeEdit" />
              </span>
            </b-form-group>

            <b-row class="mt-2">
              <b-col sm="6">
                <div class="files">
                  <expandable-image :src="formEdit.files" />
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" md="6">
            <b-form-group label="Foto KTP Petugas (Format: JPG, JPEG, PNG)" label-for="input-foto-ktp" class="mb-0">
              <span class="upload-btn-wrapper">
                <button class="btn-upload btn-sm">
                  <i class="fa fa-paperclip"></i> Pilih File
                </button>
                <input ref="imageUploader" type="file" accept="image/jpg, image/jpeg, image/png" prepend-icon="mdi-camera"
                  @change="onFileChangeEditKTP" />
              </span>
            </b-form-group>

            <b-row class="mt-2">
              <b-col sm="12">
                <div class="ktp">
                  <template v-if="formEdit.ktp">
                    <expandable-image :src="formEdit.ktp" />
                  </template>

                  <template v-else>
                    <expandable-image :src="$defaultKTP" />
                  </template>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <div class="mt-4 text-right">
          <b-button variant="pgiBtn" type="submit"><i class="fa fa-save"></i> Simpan Perubahan</b-button>
          <b-button class="ml-2" variant="dark" @click="hideModal"><i class="fa fa-times"></i> Batal</b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- Modal Detail -->
    <b-modal id="m-detail-maintenance" size="md" title="Detail Data Petugas" ref="my-modal" @hidden="hideModal"
      no-close-on-backdrop no-close-on-esc hide-footer>
      <b-row class="mb-1">
        <b-col cols="12" sm="12" md="4">
          <span class="font-weight-bold">Nama</span>
        </b-col>
        <b-col cols="12" sm="12" md="8">
          <span>{{ formDetail.name }}</span>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col cols="12" sm="12" md="4">
          <span class="font-weight-bold">Nama Pengguna</span>
        </b-col>
        <b-col cols="12" sm="12" md="8">
          <span>{{ formDetail.username }}</span>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col cols="12" sm="12" md="4">
          <span class="font-weight-bold">Wilayah</span>
        </b-col>
        <b-col cols="12" sm="12" md="8">
          <span>{{ formDetail.area }}</span>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col cols="12" sm="12" md="4">
          <span class="font-weight-bold">Kategori Petugas</span>
        </b-col>
        <b-col cols="12" sm="12" md="8">
          <span>{{ formDetail.status }}</span>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col cols="12" sm="12" md="4">
          <span class="font-weight-bold">No. Telp</span>
        </b-col>
        <b-col cols="12" sm="12" md="8">
          <span>{{ formDetail.telp }}</span>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col cols="12" sm="12" md="4">
          <span class="font-weight-bold">Email</span>
        </b-col>
        <b-col cols="12" sm="12" md="8">
          <span>{{ formDetail.email == null ? "-" : formDetail.email }}</span>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col cols="12" sm="12" md="4">
          <span class="font-weight-bold">Keterangan</span>
        </b-col>
        <b-col cols="12" sm="12" md="8">
          <span>{{ formDetail.keterangan }}</span>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col cols="12">
          <hr />
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col cols="12" sm="12" md="5" class="mb-1">
          <b-row>
            <b-col cols="12">
              <span class="font-weight-bold">Foto</span>
            </b-col>
            <b-col cols="12">
              <div class="files">
                <expandable-image :src="formDetail.foto" />
              </div>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" sm="12" md="7" class="mb-1">
          <b-row>
            <b-col cols="12">
              <span class="font-weight-bold">KTP</span>
            </b-col>
            <b-col cols="12" sm="12">
              <div class="ktp">
                <template v-if="formDetail.ktp">
                  <expandable-image :src="formDetail.ktp" />
                </template>

                <template v-else>
                  <expandable-image :src="$defaultKTP" />
                </template>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "/node_modules/vuelidate";
import { required } from "/node_modules/vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data() {
    return {
      initStatus: 0,
      isBusy: true,
      filter: {
        selectFilter: null,
        nama: "",
        hp: null,
        status: null,
      },
      file_photo: null,
      file_ktp: null,
      form: {
        nama: null,
        username: null,
        no_telp: null,
        email: null,
        password: null,
        pekerjaan: "-",
        keterangan: "-",
        area: null,
        status: null,
        files: null,
        ktp: null,
      },
      formEdit: {
        id: null,
        nama: null,
        username: null,
        no_telp: null,
        email: null,
        password: null,
        pekerjaan: "-",
        keterangan: "-",
        area: null,
        status: null,
        files: null,
        ktp: null,
        active: false,
        isActive: null,
      },
      formDelete: {
        activeName: null,
        activeId: null,
      },
      formDetail: {
        nama: null,
        username: null,
        no_telp: null,
        email: null,
        pekerjaan: null,
        keterangan: null,
        area: null,
        status: null,
        files: null,
        ktp: null,
      },
      fields: [
        {
          key: "name",
          label: "Nama Petugas",
          thClass: "nameOfTheClassTh",
          sortable: true,
        },
        {
          key: "area",
          label: "Wilayah",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "area",
          label: "wilayah",
          thClass: "nameOfTheClassTh",
          sortable: true,
        },
        {
          key: "telp",
          label: "No. HP",
          thClass: "nameOfTheClassThHP",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "status",
          label: "Kategori Petugas",
          thClass: "nameOfTheClassTh",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "status_aktif",
          label: "Status",
          thClass: "nameOfTheClassThFoto",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },
        {
          key: "foto",
          label: "Foto",
          thClass: "nameOfTheClassThFoto",
          tdClass: "nameOfTheClassTd",
          sortable: true,
        },

        {
          key: "action",
          label: "Aksi",
          thClass: "nameOfTheClassThAction",
          tdClass: "nameOfTheClassTd",
        },
      ],
      optionStatusMaintenance: [
        {
          value: "Pusat",
          label: "Pusat",
        },
        {
          value: "Cabang",
          label: "Cabang",
        },
        {
          value: "Pengemudi",
          label: "Pengemudi",
        },
      ],
      optionsFindStatusBy: [
        {
          value: 1,
          label: "Nama Petugas",
        },
        {
          value: 2,
          label: "No. HP",
        },
        // {
        //   value: 3,
        //   label: "Status Petugas",
        // },
      ],
      optionsFindStatus: [
        {
          value: 0,
          label: "Tidak Aktif",
        },
        {
          value: 1,
          label: "Aktif",
        },
      ],
      maintenance: [],
      users: [],
      currentPage: 1,
      perPage: null,
      rows: null,
      totalViewText: "",
      page: "",
      size: "",
      from: "",
      limit: "",
      loading: false,
      query: "",
      detail: null,
      lengthEditImage: 0,
      msgValidationUsername: "",
      classMsgValidationUsername: "",
      isDisabled: false,
    };
  },
  validations: {
    form: {
      nama: { required },
      username: { required },
      no_telp: { required },
      password: { required },
      area: { required },
      status: { required },
    },

    formEdit: {
      nama: { required },
      username: { required },
      no_telp: { required },
      area: { required },
      status: { required },
    },
  },

  methods: {
    //----------------- Initial Function -----------------//
    init() {
      this.initStatus = 0;
      let maintenance = "";

      // Maintenance
      maintenance = this.$axios
        .get("api/internal-memo/user-maintenance/paginate")
        .then((response) => {
          let datas = response.data.data;

          for (let data of datas.data) {
            this.maintenance.push({
              id: data.id,
              name: data.nama,
              username: data.username,
              area: data.wilayah,
              status: data.status,
              telp: data.no_telp,
              email: data.user.email,
              keterangan: data.keterangan,
              foto: data.foto,
              ktp: data.ktp,
              status_aktif: data.is_active,
            });
          }

          this.perPage = datas.per_page;
          this.size = this.perPage;
          this.from = datas.from;
          this.limit = datas.to;

          this.updateTotalItem(datas.total);
        });

      maintenance = this.$axios
        .get("api/admin/management-users")
        .then((response) => {
          if (response.status == 200) {
            let datas = response.data.data;

            for (let data of datas.data) {
              this.users.push({
                id: data.id,
                username: data.username,
              });
            }
          }
        });

      Promise.all([maintenance])
        .then(() => {
          this.initStatus = 1;
          this.toggleBusy();
        })
        .catch((error) => {
          console.log(error);
          this.initStatus = -1;
          this.toggleBusy();
        });
    },

    //----------------- Pagination, Sorting, dan Filtering Function -----------------//
    onPageChange() {
      if (this.loading == false) {
        this.page = this.currentPage;
        this.query = "?page=" + this.page;
        this.getMaintenance();
      }
    },

    toggleBusy() {
      if (this.isBusy == true) {
        this.isBusy = false;
      } else {
        this.isBusy = true;
      }
    },

    updateTotalItem(total) {
      this.perPage = this.size;
      this.rows = total;

      const resultFrom = this.from == null ? 0 : this.from;
      const resultLimit = this.limit == null ? 0 : this.limit;

      this.totalViewText = resultFrom + " - " + resultLimit;
    },

    //-----------------  Callback Function -----------------//
    getMaintenance: function () {
      this.toggleBusy();

      if (this.filter.name) {
        this.query = "?nama=" + this.filter.name;
      } else if (this.filter.hp) {
        this.query = "?no_telp=" + this.filter.hp;
      } else if (this.filter.status) {
        this.query = "?flag=" + this.filter.status;
      } else {
        this.query = "?page=" + this.page;
      }

      this.$axios
        .get("api/internal-memo/user-maintenance/paginate" + this.query)
        .then((response) => {
          if (response.status == 200) {
            this.toggleBusy();
            this.maintenance = [];
            let datas = response.data.data;

            for (var data of datas.data) {
              this.maintenance.push({
                id: data.id,
                name: data.nama,
                username: data.username,
                area: data.wilayah,
                status: data.status,
                telp: data.no_telp,
                email: data.user.email,
                password: data.password,
                keterangan: data.keterangan,
                foto: data.foto,
                ktp: data.ktp,
                status_aktif: data.is_active,
              });
            }

            this.perPage = datas.per_page;
            this.size = this.perPage;
            this.from = datas.from;
            this.limit = datas.to;

            this.updateTotalItem(datas.total);
          }
        })
        .catch((error) => {
          console.log(error);
          this.toggleBusy();
        });
    },

    checkUsernameCreate: function () {
      var usernameCreate = this.form.username.trim();

      this.form.username = this.form.username.toLowerCase();

      if (usernameCreate.length > 2) {
        let check = this.users.find((item) => item.username == usernameCreate);
        if (check) {
          this.msgValidationUsername = "Nama pengguna ini sudah terdaftar!";
          this.classMsgValidationUsername = "danger";
          this.isDisabled = true;
        } else {
          this.msgValidationUsername = "Nama pengguna ini tersedia";
          this.classMsgValidationUsername = "success";
          this.isDisabled = false;
        }
      } else {
        this.msgValidationUsername = "";
        this.classMsgValidationUsername = "";
        this.isDisabled = false;
      }
    },

    checkUsernameUpdate: function () {
      var usernameUpdate = this.formEdit.username.trim();
      this.formEdit.username = this.formEdit.username.toLowerCase();

      if (usernameUpdate.length > 2) {
        let check = this.users.find((item) => item.email == usernameUpdate);
        if (check) {
          this.msgValidationUsername = "Nama pengguna tidak tersedia!";
          this.classMsgValidationUsername = "danger";
        } else {
          this.msgValidationUsername = "Nama pengguna tersedia";
          this.classMsgValidationUsername = "success";
        }
      } else {
        this.msgValidationUsername = "";
        this.classMsgValidationUsername = "";
      }
    },

    handleInputOnKeyupCreate: function () {
      this.form.email = this.form.email.toLowerCase();
    },

    handleInputOnKeyupUpdate: function () {
      this.formEdit.email = this.formEdit.email.toLowerCase();
    },

    // Custom Input Text to Just Number
    NumbersOnly: (event) => {
      event = event ? event : window.event;
      var charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.preventDefault();
      } else {
        return true;
      }
    },

    onResetFilter: function () {
      this.filter = {
        selectFilter: null,
        nama: "",
        hp: null,
        status: null,
      };

      this.getMaintenance();
    },

    onResetInputFilter: function () {
      this.filter.nama = "";
      this.filter.hp = null;
      this.filter.status = null;

      this.getMaintenance();
    },

    //-----------------  Reset Function -----------------//
    resetForm: function () {
      this.form = {
        nama: null,
        no_telp: null,
        username: null,
        email: null,
        password: null,
        pekerjaan: "-",
        keterangan: "-",
        area: null,
        status: null,
        files: null,
        ktp: null,
      };

      this.formEdit = {
        id: null,
        nama: null,
        username: null,
        no_telp: null,
        email: null,
        password: null,
        pekerjaan: "-",
        keterangan: "-",
        area: null,
        status: null,
        files: null,
        ktp: null,
        filesUpdate: null,
        ktpUpdate: null,
        isActive: null,
      };

      this.formDelete = {
        activeName: null,
        activeId: null,
      };

      this.formDetail = {
        nama: null,
        username: null,
        no_telp: null,
        email: null,
        pekerjaan: null,
        keterangan: null,
        area: null,
        status: null,
        files: null,
        ktp: null,
      };

      this.msgValidationUsername = "";
      this.classMsgValidationUsername = "";

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    //-----------------  Hide Modal Function -----------------//
    hideModal: function () {
      this.$refs["my-modal"].hide();
      this.resetForm();
    },

    // Upload Create File Function
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.file_photo = e.target.files[0]
      this.createImage(files);
    },

    createImage(files) {
      var vm = this;
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();

        reader.onload = function (event) {
          const imageUrl = event.target.result;
          vm.form.files = imageUrl;
        };
        reader.readAsDataURL(files[index]);
      }
    },

    // Upload Create File KTP
    onFileChangeKTP(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.file_ktp = e.target.files[0]
      this.createImageKTP(files);
    },

    createImageKTP(files) {
      var vm = this;
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();

        reader.onload = function (event) {
          const imageUrl = event.target.result;
          vm.form.ktp = imageUrl;
        };
        reader.readAsDataURL(files[index]);
      }
    },

    //-----------------  Confirm Create Function -----------------//
    onConfirm: function () {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      } else {
        this.showSweetAlertCreate();
        this.$bvModal.hide("m-create-maintenance");
      }
    },

    //-----------------  Create Function -----------------//
    // showSweetAlertCreate: function () {
    //   let form = "";
    //   const Toast = this.$swal.mixin({
    //     toast: true,
    //     position: "top-end",
    //     showConfirmButton: false,
    //     timer: 3500,
    //     timerProgressBar: true,
    //     didOpen: (toast) => {
    //       toast.addEventListener("mouseenter", this.$swal.stopTimer);
    //       toast.addEventListener("mouseleave", this.$swal.resumeTimer);
    //     },
    //   });

    //   if (this.form.files && this.form.ktp) {
    //     form = {
    //       nama: this.form.nama,
    //       username: this.form.username,
    //       no_telp: this.form.no_telp,
    //       email: this.form.email,
    //       password: this.form.password,
    //       wilayah: this.form.area,
    //       status: this.form.status,
    //       pekerjaan: this.form.pekerjaan,
    //       keterangan: this.form.keterangan,
    //       role_id: 100,
    //       foto: this.form.files,
    //       ktp: this.form.ktp,
    //     };
    //   } else if (this.form.ktp) {
    //     form = {
    //       nama: this.form.nama,
    //       username: this.formEdit.username,
    //       no_telp: this.form.no_telp,
    //       email: this.form.email,
    //       password: this.form.password,
    //       wilayah: this.form.area,
    //       status: this.form.status,
    //       pekerjaan: this.form.pekerjaan,
    //       keterangan: this.form.keterangan,
    //       role_id: 100,
    //       ktp: this.form.ktp,
    //     };
    //   } else if (this.form.files) {
    //     form = {
    //       nama: this.form.nama,
    //       username: this.form.username,
    //       no_telp: this.form.no_telp,
    //       email: this.form.email,
    //       password: this.form.password,
    //       wilayah: this.form.area,
    //       status: this.form.status,
    //       pekerjaan: this.form.pekerjaan,
    //       keterangan: this.form.keterangan,
    //       role_id: 100,
    //       foto: this.form.files,
    //     };
    //   } else {
    //     form = {
    //       nama: this.form.nama,
    //       username: this.form.username,
    //       no_telp: this.form.no_telp,
    //       email: this.form.email,
    //       password: this.form.password,
    //       wilayah: this.form.area,
    //       status: this.form.status,
    //       pekerjaan: this.form.pekerjaan,
    //       keterangan: this.form.keterangan,
    //       role_id: 100,
    //     };
    //   }

    //   this.$swal({
    //     html: "<span class='font-weight-bold'>Konfirmasi</span><span>, untuk melanjutkan penyimpanan data petugas.</span>",
    //     type: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#1e73be",
    //     cancelButtonText: "Batal",
    //     confirmButtonText: "Konfirmasi",
    //     closeOnConfirm: false,
    //     closeOnCancel: false,
    //     reverseButtons: true,
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       this.$swal({
    //         title: "Mohon Menunggu",
    //         html: "Penyimpanan data petugas sedang diproses...",
    //         allowOutsideClick: false,
    //         showConfirmButton: false,
    //         willOpen: () => {
    //           this.$swal.showLoading();
    //         },
    //       });
    //       this.$axios
    //         .post("/api/internal-memo/user-maintenance/test", form)
    //         .then((response) => {
    //           if (response.status == 200) {
    //             Toast.fire({
    //               icon: "success",
    //               html: "Selamat! Data petugas berhasil tersimpan &#128516",
    //             });
    //           }
    //           this.resetForm();
    //           this.getMaintenance();
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //           Toast.fire({
    //             icon: "error",
    //             html: "Data petugas gagal disimpan &#128532",
    //           });
    //         });
    //     } else {
    //       this.$bvModal.show("m-create-maintenance");
    //     }
    //   });
    // },


    showSweetAlertCreate: function () {
      const form = new FormData()


      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      if (this.form.files && this.form.ktp) {
        form.append('nama', this.form.nama)
        form.append('username', this.form.username)
        form.append('no_telp', this.form.no_telp)
        form.append('email', this.form.email)
        form.append('password', this.form.password)
        form.append('wilayah', this.form.wilayah)
        form.append('status', this.form.status)
        form.append('pekerjaan', this.form.pekerjaan)
        form.append('keterangan', this.form.keterangan)
        form.append('role_id', 100)
        form.append('foto', this.file_photo)
        form.append('ktp', this.file_ktp)
      } else if (this.form.ktp) {
        form.append('nama', this.form.nama)
        form.append('username', this.form.username)
        form.append('no_telp', this.form.no_telp)
        form.append('email', this.form.email)
        form.append('password', this.form.password)
        form.append('wilayah', this.form.wilayah)
        form.append('status', this.form.status)
        form.append('pekerjaan', this.form.pekerjaan)
        form.append('keterangan', this.form.keterangan)
        form.append('role_id', 100)
        form.append('ktp', this.file_ktp)
      } else if (this.form.files) {
        form.append('nama', this.form.nama)
        form.append('username', this.form.username)
        form.append('no_telp', this.form.no_telp)
        form.append('email', this.form.email)
        form.append('password', this.form.password)
        form.append('wilayah', this.form.wilayah)
        form.append('status', this.form.status)
        form.append('pekerjaan', this.form.pekerjaan)
        form.append('keterangan', this.form.keterangan)
        form.append('role_id', 100)
        form.append('foto', this.file_photo)
      } else {

        form.append('nama', this.form.nama)
        form.append('username', this.form.username)
        form.append('no_telp', this.form.no_telp)
        form.append('email', this.form.email)
        form.append('password', this.form.password)
        form.append('wilayah', this.form.wilayah)
        form.append('status', this.form.status)
        form.append('pekerjaan', this.form.pekerjaan)
        form.append('keterangan', this.form.keterangan)
        form.append('role_id', 100)
      }

      this.$swal({
        html: "<span class='font-weight-bold'>Konfirmasi</span><span>, untuk melanjutkan penyimpanan data petugas.</span>",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon Menunggu",
            html: "Penyimpanan data petugas sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });
          this.$axios
            .post("/api/internal-memo/user-maintenance/test", form)
            .then((response) => {
              if (response.status == 200) {
                Toast.fire({
                  icon: "success",
                  html: "Selamat! Data petugas berhasil tersimpan &#128516",
                });
              }
              this.resetForm();
              this.getMaintenance();
            })
            .catch((error) => {
              console.log(error);
              Toast.fire({
                icon: "error",
                html: "Data petugas gagal disimpan &#128532",
              });
            });
        } else {
          this.$bvModal.show("m-create-maintenance");
        }
      });
    },

    //-----------------  Edit Function -----------------//
    showModalEdit: function (
      id,
      nama,
      username,
      telp,
      area,
      status,
      status_aktif,
      email,
      keterangan,
      foto,
      ktp
    ) {
      if (status_aktif == 1) {
        this.formEdit.active = true;
      } else if (status_aktif == 0) {
        this.formEdit.active = false;
      }

      if (foto !== "null") {
        this.formEdit.files = foto;
      }

      if (ktp !== "null") {
        this.formEdit.ktp = ktp;
      }

      this.formEdit.id = id;
      this.formEdit.nama = nama;
      this.formEdit.username = username;
      this.formEdit.no_telp = telp;
      this.formEdit.area = area;
      this.formEdit.status = status;
      this.formEdit.email = email == "null" ? "-" : email;
      this.formEdit.keterangan = keterangan;
      this.formEdit.isActive = status_aktif;

      this.$bvModal.show("m-edit-maintenance");
    },

    checkActive: function (e) {
      if (e) {
        this.formEdit.isActive = 1;
      } else {
        this.formEdit.isActive = 0;
      }
    },

    // Upload Edit File Function
    onFileChangeEdit(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.editImage(files);
    },

    editImage(files) {
      var vm = this;
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();

        reader.onload = function (event) {
          const imageUrl = event.target.result;
          vm.formEdit.filesUpdate = imageUrl;
          vm.formEdit.files = imageUrl;
        };
        reader.readAsDataURL(files[index]);
      }
    },

    onFileChangeEditKTP(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.editImageKTP(files);
    },

    editImageKTP(files) {
      var vm = this;
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();

        reader.onload = function (event) {
          const imageUrl = event.target.result;
          vm.formEdit.ktpUpdate = imageUrl;
          vm.formEdit.ktp = imageUrl;
        };
        reader.readAsDataURL(files[index]);
      }
    },

    //-----------------  Confirm Edit Function -----------------//
    onConfirmEdit: function () {
      this.$v.formEdit.$touch();

      if (this.$v.formEdit.$anyError) {
        return;
      } else {
        this.showSweetAlertEdit();
        this.$bvModal.hide("m-edit-maintenance");
      }
    },

    showSweetAlertEdit: function () {
      let formEdit = "";

      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      if (this.formEdit.filesUpdate && this.formEdit.ktpUpdate) {
        formEdit = {
          id: this.formEdit.id,
          nama: this.formEdit.nama,
          username: this.formEdit.username,
          password: this.formEdit.password,
          pekerjaan: this.formEdit.pekerjaan,
          no_telp: this.formEdit.no_telp,
          wilayah: this.formEdit.area,
          status: this.formEdit.status,
          email: this.formEdit.email,
          keterangan: this.formEdit.keterangan,
          foto: this.formEdit.filesUpdate,
          ktp: this.formEdit.ktpUpdate,
          is_active: this.formEdit.isActive,
        };
      } else if (this.formEdit.ktpUpdate) {
        formEdit = {
          id: this.formEdit.id,
          nama: this.formEdit.nama,
          username: this.formEdit.username,
          password: this.formEdit.password,
          pekerjaan: this.formEdit.pekerjaan,
          no_telp: this.formEdit.no_telp,
          wilayah: this.formEdit.area,
          status: this.formEdit.status,
          email: this.formEdit.email,
          keterangan: this.formEdit.keterangan,
          ktp: this.formEdit.ktpUpdate,
          is_active: this.formEdit.isActive,
        };
      } else if (this.formEdit.filesUpdate) {
        formEdit = {
          id: this.formEdit.id,
          nama: this.formEdit.nama,
          username: this.formEdit.username,
          password: this.formEdit.password,
          pekerjaan: this.formEdit.pekerjaan,
          no_telp: this.formEdit.no_telp,
          wilayah: this.formEdit.area,
          status: this.formEdit.status,
          email: this.formEdit.email,
          keterangan: this.formEdit.keterangan,
          foto: this.formEdit.filesUpdate,
          is_active: this.formEdit.isActive,
        };
      } else {
        formEdit = {
          id: this.formEdit.id,
          nama: this.formEdit.nama,
          username: this.formEdit.username,
          password: this.formEdit.password,
          pekerjaan: this.formEdit.pekerjaan,
          no_telp: this.formEdit.no_telp,
          wilayah: this.formEdit.area,
          status: this.formEdit.status,
          email: this.formEdit.email,
          keterangan: this.formEdit.keterangan,
          is_active: this.formEdit.isActive,
        };
      }
      console.log(formEdit);
      this.$swal({
        title: "Konfirmasi",
        html: "<span class='font-weight-bold'>Konfirmasi</span><span>, untuk melanjutkan perubahan data petugas.</span>",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon Menunggu",
            html: "Perubahan data petugas sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .post(
              "/api/internal-memo/user-maintenance/" + formEdit.id,
              formEdit
            )
            .then((response) => {
              if (response.status == 200) {
                Toast.fire({
                  icon: "success",
                  html: "Selamat! Perubahan data petugas berhasil tersimpan &#128516",
                });
              }
              this.resetForm();
              this.getMaintenance();
            })
            .catch((error) => {
              console.log(error);
              Toast.fire({
                icon: "error",
                html: "Perubahan data petugas gagal disimpan &#128532",
              });
            });
        } else {
          this.resetForm();
        }
      });
    },

    //-----------------  Delete Function -----------------//
    showSweetAlertDelete: function (id, name) {
      this.formDelete.activeId = id;
      this.formDelete.activeName = name;

      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      const nama = this.formDelete.activeName;
      const contentJenis = document.createElement("div");

      contentJenis.innerHTML =
        '<strong>Konfirmasi</strong>, untuk melanjutkan proses penghapusan data petugas a/n <strong>"' +
        nama +
        '"</strong>?';

      this.$swal({
        html: contentJenis,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: "Mohon Menunggu",
            html: "Penghapusan data petugas sedang diproses...",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              this.$swal.showLoading();
            },
          });

          this.$axios
            .delete(
              "api/internal-memo/user-maintenance/delete/" +
              this.formDelete.activeId
            )
            .then((response) => {
              if (response.status == 200) {
                this.$swal({
                  title: "Sukses!",
                  text: "Data pegawai telah berhasil dihapus.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                });

                Toast.fire({
                  icon: "success",
                  html: "Selamat! Data petugas berhasil terhapus &#128516",
                });
              }

              this.resetForm();
              this.getMaintenance();
            })
            .catch((error) => {
              this.$swal({
                title: "Gagal",
                html: "Data pegawai gagal dihapus. <br> (" + error + ")",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
              });
              console.log(error);
              Toast.fire({
                icon: "error",
                html: "Maaf, data petugas gagal dihapus &#128532",
              });
            });
        } else {
          this.resetForm();
        }
      });
    },

    showModalDetail: function (id) {
      this.detail = id;

      let find = this.maintenance.find((item) => item.id == this.detail);

      this.formDetail = {
        name: find.name,
        username: find.username,
        area: find.area,
        status: find.status,
        telp: find.telp,
        email: find.email,
        keterangan: find.keterangan,
        foto: find.foto,
        ktp: find.ktp,
      };

      this.$bvModal.show("m-detail-maintenance");
    },
  },

  //-----------------  Initial Function -----------------//
  created() {
    this.init();
  },
};
</script>

<style lang="scss">
.required label::after {
  content: "*";
  color: #f79483;
}

.b-table-empty-row {
  text-align: center;
}

.table th,
.table td {
  vertical-align: middle;
}

.nameOfTheClassTh {
  text-align: center;
}

.nameOfTheClassThAction {
  width: 8%;
  text-align: center;
}

.nameOfTheClassTd {
  text-align: center;
}

.nameOfTheClassThHP {
  width: 15%;
  text-align: center;
}

.nameOfTheClassThFoto {
  width: 8%;
  text-align: center;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-upload {
  padding: 8px 20px;
  background-color: #808080;
  border: none;
  border-radius: 5px;
  color: white;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  transition: 100ms ease-out;
  cursor: pointer;
}

.upload-btn-wrapper:hover {
  border-radius: 5px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

div.img-table .expandable-image img {
  width: 70px;
  height: 80px;
  object-fit: cover;
}

div.files .expandable-image img {
  width: 150px;
  height: 200px;
  object-fit: cover;
}

div.ktp .expandable-image img {
  height: 200px;
  object-fit: contain;
}

.my-switch {
  align-self: center;
}
</style>
